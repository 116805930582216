import React, { useEffect } from "react";
import img1 from "../../assets/AboutUs/pexels-teddy-joseph-2955375@2x.png";
import Responsive from "./TeamRATSlider";
import AboutPageHeading from "./AboutPageHeading";
import Aos from "aos";

const TeamRAT = () => {
  const images = [img1, img1, img1, img1, img1]; // Array of images

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className="md:pb-[5.56vh] md:pl-[6.77vw] md:pr-[4.58vw] ">
      <div className="">
        <AboutPageHeading headingText="Team R.A.T" />
      </div>
      <div
        className="md:pt-[5.56vh]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <Responsive
          images={images}
          fName={"Jack"}
          lName={"Johansan"}
          position={"(Assistant Director )"}
        />
      </div>
    </div>
  );
};

export default TeamRAT;
