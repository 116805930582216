import { MdOutlineChevronRight } from "react-icons/md";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";

const Pageheading = ({ pageHeading, icon ,className}) => { 
  
  useEffect(() => {
    Aos.init({ 
      easing: 'ease-in', 
      duration: 1500,
      once: false,
      delay: 200,
    });
  }, []);

  return (
    <h1 className={`flex items-center text-white  font-montserrat leading-[2.19vw] gap-x-2 md:text-[0.94vw] text-[3vw] ${className}`}
    data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
    > 
    
      <div className=" flex items-center  opacity-100">
        <Link to="/">
        <span className="cursor-pointer">{icon}</span>
        </Link>

        <MdOutlineChevronRight size={14} />
        <span>{pageHeading}</span>
      </div>
    </h1>
  );
};

export default Pageheading;
