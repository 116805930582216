import React from "react";
import bgimg from '../../assets/AboutUs/film_texture___grain_explosion_by_stephanepellennec_d37pwfa-fullview.png';
import img1 from '../../assets/AboutUs/hotstar.png';
import img2 from '../../assets/AboutUs/jiocinema.png';
import img3 from '../../assets/AboutUs/pepsi.png';
import img4 from '../../assets/AboutUs/prime.png';

const images = [
  img1, img2, img3, img4, img1, img2, img3, img4, 
  img1, img2, img3, img4
];

function StaticGrid() {
  return (
    <div className="px-4 md:px-0">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-[4.27vw] md:gap-[1.90vw]">
        {images.map((image, index) => (
          <div key={index} className="flex justify-center  items-center cursor-pointer">
            <a href="https://www.hotstar.com" target="_blank" rel="noopener noreferrer">
              <div
                className="w-[44.27vw] md:w-[20.68vw] h-[18.98vh] md:h-[26.11vh] flex justify-center items-center bg-cover bg-center overflow-hidden"
                style={{ backgroundImage: `url(${bgimg})` }}
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-cover filter transition duration-300 ease-in-out"
                />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StaticGrid;
