import Aos from "aos";
import React, { useEffect, useState } from "react";
import { BiRightArrowAlt } from "react-icons/bi";

const Button = ({ buttonText, onClick, customCss }) => { 
  useEffect(() => {
    Aos.init({ 
      duration: 1500, 
      once: false, 
    });
  }, []);
  return (
    <button
      type="submit"
      className={`flex items-center md:gap-x-[0.83vw] gap-x-3  cursor-pointer  relative overflow-visible group ${customCss}`}
      onClick={onClick} 
      data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
    >
      <span className="text-white uppercase font-montserrat font-semibold  text-[3.73vw] md:text-[1.25vw] transition-transform duration-300 ease-in-out group-hover:scale-105 transform origin-center">
        {buttonText}
      </span>
      <span className="relative">
        <div className="flex items-center justify-center bg-[#4AB66C] rounded-full p-[2px] transition-colors duration-300 ease-in-out group-hover:bg-white">
          <BiRightArrowAlt
            // size={37.5}
            className="text-white md:w-[2.60vw] w-[6.5vw] h-auto  -rotate-45 transition-transform duration-300 ease-in-out group-hover:rotate-0 group-hover:text-[#22AD86]"
          />
        </div>
      </span>
    </button>
  );
};

export default Button;
