/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import Tag_img from "../../assets/webSeries/tag_img.png";
import { BiRightArrowAlt } from "react-icons/bi";
import CustomPlaybutton from "../../components/Buttons/CustomPlaybutton";
import desktop_bg from "../../assets/Detailpage/bg.png";
import bg from "../../assets/bg_image/banner.png";

import "./Category.css";
const Detail = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  console.log("videoID", id);

  const moreinfodata = [
    {
      id: 1,
      title: "CONTENT ADVISORY",
      description: "Foul language",
    },
    {
      id: 2,
      title: "AUDIO LANGUAGES",
      description: "Hindi, English, Tamil, Marathi, Telugu",
    },
    {
      id: 3,
      title: "SUBTITLES",
      description: "English",
    },
    {
      id: 4,
      title: "STUDIO",
      description: "RAT Films",
    },
    {
      id: 5,
      title: "DIRECTORS",
      description: "Ashutosh Shah",
    },
  ];

  const handleVideoLoad = useCallback(() => {
    setIsVideoLoaded(true);
  }, []);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleWatchButton = (videoId) => {
    navigate(`/watch/${videoId}`);
  };

  const dummyData = [
    {
      id: 1,
      src: "Web_img.png",
      headingText: "WEB SERIES",
      imageUrl: Tag_img,
      releaseInfo: {
        year: "2024",
        season: "1 Season",
        languages: "7 Languages",
        rating: "U/A 16+",
      },
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
      buttonText: "WATCH TRAILER",
      videoId: "276703567", // Ensure you have a valid videoId for the iframe
    },
  ];

  return (
    <div className="w-full h-full m-0 p-0">
      <div
        className="relative w-full h-screen !overflow-hidden bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url(${window.innerWidth < 768 ? bg : desktop_bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && (
          <div className="absolute inset-0 z-10 ">
            <div className="vimeo-full-width">
              {/* Local video for mobile devices */}
              <video
                src="/video/videoplayback.mp4" // Local video from public folder
                className={`mobile-video w-full h-full absolute top-0 left-0  transition-opacity duration-500 ${
                  isHovered || isVideoLoaded ? "opacity-100" : "opacity-0"
                }`}
                autoPlay={isHovered}
                loop
                muted
                controls={false}
                onCanPlay={handleVideoLoad}
                onError={() => console.error("Failed to load the local video.")}
                style={{ pointerEvents: "none" }}
              />

              {/* Vimeo iframe for larger screens */}
              <iframe
                src={`https://player.vimeo.com/video/${id}?playsinline=0&autoplay=${
                  isHovered ? 1 : 0
                }&loop=1&muted=1&controls=0`}
                className={`desktop-video w-full h-full absolute top-0 left-0  transition-opacity duration-500 ${
                  isHovered || isVideoLoaded ? "opacity-100" : "opacity-0"
                }`}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                onLoad={handleVideoLoad}
                onError={() => console.error("Failed to load the Vimeo video.")}
                style={{ pointerEvents: "none" }}
              ></iframe>
            </div>
          </div>
        )}

        {!isHovered && (
          <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center">
            <CustomPlaybutton onClick={() => navigate(`/watch/${id}`)} />
          </div>
        )}

        <div className="md:pl-[5.63vw]  pl-[5vw] z-10 flex items-center relative pt-[18vh] md:pt-[10.29vw]">
          <Pageheading
            pageHeading={"Shorts & Documentaries"}
            icon={<RiHome2Line />}
          />
        </div>

        {dummyData.map((data) => (
          <div
            className={` relative z-30 md:px-[7vw] flex flex-col items-center h-full lg:items-start  justify-end lg:justify-start lg:gap-y-[1.77vh] gap-y-[1.7vh] px-[3.27vw]   pb-[25vh] lg:pt-[8vh] lg:pb-0 
        `}
          >
            <div className="lg:w-[22.73vw] lg:h-[14.17vh] w-[50.67vw]">
              <img
                src={data.imageUrl}
                alt=""
                className="h-full w-full object-fill"
              />
            </div>
            <div className="flex flex-wrap items-center lg:pt-[1.5vh]  text-[#22AD86] text-left lg:text-[1.25vw]  text-[4vw] font-semibold font-montserrat  leading-[3.15vh]">
              {data.headingText}
            </div>
            <div className="flex flex-wrap items-center space-x-2 text-white text-left lg:text-[1.25vw] text-[4vw] font-semibold font-montserrat leading-[3.15vh]">
              <div>{data.releaseInfo.year}</div>
              <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
              <div>{data.releaseInfo.season}</div>
              <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
              <div>{data.releaseInfo.languages}</div>
              <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
              <div className="text-white bg-[#36c31f69] rounded-[4px] md:p-[2px] px-[3px]">
                <span className="text-white whitespace-pre-line lg:text-[1.25vw] text-[4vw] leading-none brightness-200">
                  {data.releaseInfo.rating}
                </span>
              </div>
            </div>
            <p className="whitespace-pre-line font-montserrat text-white opacity-100 font-normal md:text-[1.25vw] text-16  lg:w-[40%] hidden lg:block  ">
              {data.description}
            </p>
            <div className="lg:flex items-center space-x-3 lg:pt-[1.99vh] text-white lg:text-[1.25vw] text-16 font-semibold font-montserrat lg:leading-[3.15vh] opacity-100  hidden ">
              {data.genres.map((genre, index) => (
                <React.Fragment key={index}>
                  <span>{genre}</span>
                  {index < data.genres - 1 && (
                    <div className="w-[3px] h-6 bg-white"></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="border-[1px] border-white lg:px-[1.30vw] py-[1.78vh] px-[5vw] p-[2vw] lg:mt-[3.09vh] rounded-full lg:rounded-[3.40vw]  relative overflow-hidden group">
              <button
                className="flex items-center  gap-x-3 text-white uppercase font-montserrat leading-0 text-[4vw] lg:text-[1.25vw] font-semibold opacity-100 relative z-10"
                onClick={() => handleWatchButton(id)}
              >
                Watch Trailer
                <div className="flex items-center justify-center bg-[#4AB66C] rounded-full p-[2px] transition-colors duration-300 ease-in-out group-hover:bg-white">
                  <BiRightArrowAlt className="text-white w-[7vw] md:w-[2.60417vw] h-auto transition-transform duration-300 ease-in-out group-hover:text-[#22AD86] -rotate-45 group-hover:rotate-0" />
                </div>
              </button>
              <div className="absolute inset-0 bg-custom-vertical-gradient transform translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
            </div>
          </div>
        ))}
      </div>

      {/* More Info Section */}
      <section className="md:pl-[7.29vw] md-pt-0  px-[5vw] md:px-4 w-full h-full  relative">
        <h1 className="md:mt-[8.33vh] mt-[6vh] mb-[5vh] md:mb-[6.30vh]">
          <SectionHeading sectionheading={"MORE INFO"} />
        </h1>
        <div className="grid grid-cols-1  md:grid-cols-3 gap-12   ">
          {moreinfodata.map((item) => (
            <div key={item.id} className="text-left md:pb-[2.56vh]">
              <p className="text-white md:text-[1.25vw] font-montserrat text-[5vw] font-medium uppercase pb-[2vh] md:pb-[2.22vh]">
                {item.title}
              </p>
              {item.title === "STUDIO" || item.title === "DIRECTORS" ? (
                <Link to="/founders">
                  <p className="text-white md:text-[1.15vw] font-montserrat text-[4.5vw] font-light hover:opacity-80">
                    {item.description}
                  </p>
                </Link>
              ) : (
                <p className="text-white md:text-[1.15vw] font-montserrat text-[4.5vw] font-light">
                  {item.description}
                </p>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Detail;
