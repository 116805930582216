import React, { useRef, useEffect } from "react";
import FOG from "vanta/dist/vanta.fog.min"; // Import FOG directly from Vanta

const FluidBackground = () => {
  const backgroundRef = useRef(null);
  const vantaEffect = useRef(null);

  useEffect(() => {
    if (backgroundRef.current && !vantaEffect.current) {
      vantaEffect.current = FOG({
        el: backgroundRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        highlightColor: 0x0f1f19,
        midtoneColor: 0x23513b,
        lowlightColor: 0x317655,
        baseColor: 0x0f1b16,
        blurFactor: 0.9,
        speed: 2.0,
        zoom: 0.6,
      });
    }

    return () => {
      if (vantaEffect.current) {
        vantaEffect.current.destroy();
        vantaEffect.current = null;
      }
    };
  }, []);

  return (
    <div
      ref={backgroundRef}
      style={{
        width: "100%",
        height: "100%",
        position: "fixed", 
        overflow:"hidden",
        top: 0,
        left: 0,
        zIndex: 0.5,
      }}
    />
  );
};

export default FluidBackground;
