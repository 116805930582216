import React from "react";
import contactImg from "../career/img/career.png";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import Heading from "../../components/Highlightedheading/Heading.jsx";
import AboutPageHeading from "../../components/AboutUs/AboutPageHeading.jsx";
import ClientSection2 from "../../components/Client/Section2.jsx";

export const Client = () => {
  return (
    <div className="w-full h-full bg-[#0F221C] text-white">
      <div className="relative w-full h-[87vh] md:h-[60vh] lg:h-[75vh] flex items-center overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center flex justify-center items-center z-0"
          style={{
            backgroundImage: `url(${contactImg})`,
            backgroundColor: "transparent",
            width: "100vw",
            height: "87vh",
          }}
        >
          <Heading headingText={"CLIENTS"} />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 h-[93dvh]">
          <div className="md:pl-[5.63vw] pl-[3vw] flex items-center md:pt-[21vh] pt-[15vh]">
            <Pageheading pageHeading={"Clients"} icon={<RiHome2Line />} />
          </div>
        </div>

      </div>
      <div className="px-[1vw] md:px-[6vw]  relative z-20">
        <AboutPageHeading headingText="WORK | LOVE | REPEAT" />
      </div>
     
      <div className="  md:px-[6vw]">
        <ClientSection2 />
      </div>
    </div>
  );
};

export default Client;
