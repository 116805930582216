import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";
import Heading from "../Highlightedheading/Heading";
import "./partner.scss"; // Import SCSS file

// Import your local images
import img_1 from "../../assets/Partners/1.png";
import img_2 from "../../assets/Partners/2.png";
import img_3 from "../../assets/Partners/3.png";
import img_4 from "../../assets/Partners/4.png";
import img_5 from "../../assets/Partners/5.png";
import img_6 from "../../assets/Partners/6.png";
import img_7 from "../../assets/Partners/7.png";
import img_8 from "../../assets/Partners/8.png";
import img_9 from "../../assets/Partners/9.png";
import img_10 from "../../assets/Partners/10.png";
import img_11 from "../../assets/Partners/11.png";
import img_12 from "../../assets/Partners/12.png";
import img_13 from "../../assets/Partners/13.png";
import img_14 from "../../assets/Partners/14.png";
import img_15 from "../../assets/Partners/15.png";
import img_16 from "../../assets/Partners/16.png";
import img_17 from "../../assets/Partners/17.png";
import img_18 from "../../assets/Partners/18.png";
import img_19 from "../../assets/Partners/19.png";
import img_20 from "../../assets/Partners/20.png";
import img_21 from "../../assets/Partners/21.png";
import img_22 from "../../assets/Partners/22.png";
import img_23 from "../../assets/Partners/23.png";
import img_24 from "../../assets/Partners/24.png";
import img_25 from "../../assets/Partners/25.png";
import Aos from "aos";

export const Partners = () => {
  const images = [
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_6,
    img_7,
    img_8,
    img_9,
    img_10,
    img_11,
    img_12,
    img_13,
    img_14,
    img_15,
    img_16,
    img_17,
    img_18,
    img_19,
    img_20,
    img_21,
    img_22,
    img_23,
    img_24,
    img_25,
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
      delay: 200,
    });
  }, []);

  return (
    <div className="w-full h-full relative md:mt-[6.40vw] mt-[12vw]">
      <div className="outer-scratch relative w-[100vw] md:h-[50.30vh] h-[26.73vh] bg-no-repeat bg-cover bg-center z-20">
        <div className="inner-scratch">
          <div className="background grain">
            <div className="absolute z-20 top-0 md:pl-[7.29vw] px-4 py-4 md:py-0  md:pt-[7.5vh] ">
              <Heading headingText="OUR PARTNERS" />
            </div>

            <div className="absolute inset-0 flex justify-center items-center md:pt-[13.37vh]  z-30">
              <Marquee
                speed={60}
                gradient={false}
                pauseOnHover={true}
                className="carousel-container"
              >
                {images.map((img, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center px-4 image-container "
                  >
                    <img
                      src={img}
                      alt={`Partner ${index}`}
                      className={`w-full h-full object-cover grayscale-img ${
                        index === 0 ? "grayscale-0 " : ""
                      }`}
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
