import { BiRightArrowAlt } from "react-icons/bi";
import React, { useEffect } from "react";
import Heading from "../Highlightedheading/Heading";
import Button from "../Buttons/Button";
import Web_img from "../../assets/webSeries/web_img.png";
import Tag_img from "../../assets/webSeries/tag_img.png";
import Mobile_bg from "../../assets/webSeries/Mobile_bg.png";
import { useNavigate } from "react-router-dom";
import Aos from "aos";

const Section = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ 
      easing: 'ease-in', 
      duration: 1500,
      once: false,
    });
  }, []);

  const dummyData = [
    {
      id: 1,
      src: "Web_img.png",
      headingText: "WEB SERIES",
      backgroundImage: Web_img,
      mobileBackgroundImage: Mobile_bg,
      imageUrl: Tag_img,
      releaseInfo: {
        year: "2024",
        season: "1 Season",
        languages: "7 Languages",
        rating: "U/A 16+",
      },
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
      buttonText: "WATCH TRAILER",
      videoId: 330036411,
    },
  ];

  function handleWebSeries(id) {
    navigate(`/watch/${id}`);
  }

  return (
    <section
      className="w-full h-full overflow-hidden relative md:mt-[7.31vh]"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      {dummyData.map((data) => (
        <div
          key={data.id}
          className=" grid grid-rows-1 md:grid-rows-[20vw_auto] md:pl-[7.29vw]  mt-[3.70vh] md:mt-0 md:py-[9vh]  z-20  relative w-full h-full     bg-no-repeat bg-cover "
        >
          <div className="  flex  items-start  justify-between px-4   md:px-0   ">
            <Heading headingText={data.headingText} />

            <div className="absolute flex items-center justify-center md:right-[25.42vw] sm:top-[-10.04vh] top-[-4.63vh]">
              <img
                src="images/Ellipse 63.png"
                alt="elipse"
                loading="lazy"
                className="w-full h-full object-cover"
              />
            </div>

            <Button
              buttonText={"VIEW ALL"}
              onClick={() => navigate("/short-documentaries")}
              customCss={"md:mt-20 mt-6 md:pr-[3vw] !z-30 "}
            />
          </div>

          {/* Desktop background image */}
          <div
            className="hidden md:block absolute inset-0 bg-no-repeat bg-cover bg-center z-20"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="150"
            style={{
              backgroundImage: `url(${data.backgroundImage})`,
              height: "100%",
              width: "100%",
            }}
          ></div>

          {/* Mobile background image */}
          <div
            className="block md:hidden absolute inset-0 bg-no-repeat bg-cover  w-full mt-[13vh] z-20 md:mt-0"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="150"
            style={{
              backgroundImage: `url(${data.mobileBackgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              height: "100vh",
              width: "100%",
            }}
          ></div>
          {/* <div className="h-[16rem] w-full md:h-0"></div> */}
          <div
            className=" relative z-30  flex flex-col items-start justify-start md:gap-y-[1.77vh] gap-y-[1.77vh] px-4 pb-[50vh] pt-[10vh] md:pt-0 md:pb-0"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="150"
          >
            <div className="md:w-[23.54vw] w-[46.93vw]">
              <img
                src={data.imageUrl}
                alt={data.headingText}
                loading="lazy"
                className="h-auto w-full object-contain"
              />
            </div>
            <div className="flex flex-wrap items-center md:pt-[1.5vh]  text-[#22AD86] text-left md:text-[1.25vw] text-[4.27vw] font-semibold font-montserrat  leading-[3.15vh]">
              New Release
            </div>
            <div className="flex flex-wrap items-center space-x-[2vw] md:space-x-[0.8vw] text-white text-left md:text-[1.25vw] text-[4.27vw] font-semibold font-montserrat leading-[3.15vh]">
              <div>{data.releaseInfo.year}</div>
              <div className="md:w-[0.46vw] md:h-[0.46vw] w-[1.2vw] h-[1.2vw]  rounded-full bg-white opacity-50"></div>
              <div>{data.releaseInfo.season}</div>
              <div className="md:w-[0.46vw] md:h-[0.46vw] w-[1.2vw] h-[1.2vw] rounded-full bg-white opacity-50"></div>
              <div>{data.releaseInfo.languages}</div>
              <div className="md:w-[0.46vw] md:h-[0.46vw] w-[1.2vw] h-[1.2vw] rounded-full bg-white opacity-50"></div>
              <div className="text-white bg-[#36c31f69] rounded-[4px] md:p-[2px] px-[3px]">
                <span className="text-white whitespace-pre-line md:text-[1.25vw]  leading-none brightness-200">
                  {data.releaseInfo.rating}
                </span>
              </div>
            </div>
            <p className="whitespace-pre-line font-montserrat text-white opacity-100 font-normal md:text-[1.25vw] text-[4.27vw]  md:w-[38%] w-[80vw]   ">
              {data.description}
            </p>
            <div className="flex items-center space-x-3 md:pt-[1.5vh] text-white md:text-[1.25vw] text-[4.27vw]  font-semibold font-montserrat md:leading-[3.15vh] opacity-100">
              {data.genres.map((genre, index) => (
                <React.Fragment key={index}>
                  <span>{genre}</span>
                  {index < data.genres.length - 1 && (
                    <div className="w-[3px] h-6 bg-white"></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div
              className="border-[1px] border-white md:px-[1.5vw] p-[3.2vw]  md:py-[2.78vh]   md:mt-[4.09vh] mt-[2.09vh] md:rounded-[3.40vw] rounded-[46px] relative overflow-hidden group"
              onClick={() => handleWebSeries(data.videoId)}
            >
              <button className="flex items-center md:gap-x-[0.73vw] gap-x-2 text-white whitespace-nowrap uppercase font-montserrat leading-0 text-[4.27vw]  md:text-[1.24vw]  font-semibold opacity-100 relative z-10">
                {data.buttonText}

                <div className="flex items-center justify-center bg-[#4AB66C] rounded-full p-[2px] transition-colors duration-300 ease-in-out group-hover:bg-white">
                  <BiRightArrowAlt className="text-white md:w-[2.60417vw] w-[6.5vw] h-auto transition-transform duration-300 ease-in-out group-hover:text-[#22AD86] -rotate-45 group-hover:rotate-0" />
                </div>
              </button>
              <div className="absolute inset-0 bg-custom-vertical-gradient transform translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Section;
