import React, { useEffect } from "react";
import AboutPageHeading from "./AboutPageHeading";
import Award from "./Award";
import Aos from "aos";

export const Section5 = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className="md:pl-[6.77vw]">
      <div className="md:py-[5.56vh] ">
        <AboutPageHeading headingText="Awards" />
      </div>

      <div
        className=""
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <Award />
      </div>
    </div>
  );
};

export default Section5;
