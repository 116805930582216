import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Category from "./pages/CategoryPage/Category"; 
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import Services from "./pages/services/Services";
import Footer from "./components/Footer/Footer";
import SubCategoryPage from "./pages/upcoming/SubCategoryPage";
import Career from "./pages/career/Career";
import Founder from "./pages/founders/Founder";
import Contact from "./pages/contactpage/contactpage";
import Gallery from "./pages/gallery/Gallery";
import Detail from "./pages/CategoryPage/Detail";
import Client from "./pages/clients/Client";
import Media from "./pages/media/Media";
import VideoPage from "./pages/Playerpage/VideoPage";
import AboutUs from "./pages/aboutUs/AboutUs";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import FluidBackground from "./FluidBackground";

/*
Home // only skip one page
upcoming,
career,

*/

function App() {
  const location = useLocation();
  const isVideoPage = location.pathname.startsWith("/watch");
  return (
    <>
      <div className=" ">
        <FluidBackground />
        <ScrollToTop />
        {!isVideoPage && <Header />}
        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="/short-documentaries"
            element={<Category pageHeading={"Shorts & Documentaries"} />}
          >
           
          </Route>
          <Route path="/short-documentaries/details/:id" element={<Detail />} />

          <Route
            path="/:category/:categoryId/upcoming"
            element={
              <SubCategoryPage
                headingText="Upcoming"
                pageHeading="Shorts & documentaries > Upcoming"
              />
            }
          />
          <Route
            path="/:category/:categoryId/latest-release"
            element={
              <SubCategoryPage
                headingText="Latest Release"
                pageHeading="Shorts & documentaries > Latest-Release"
              />
            }
          />
          <Route
            path="/:category/:categoryId/all"
            element={
              <SubCategoryPage
                headingText="All Shorts & Documentaries"
                pageHeading="Shorts & documentaries > All "
              />
            }
          />

          <Route
            path="/commercials"
            element={<Category pageHeading={"Commercials"} />}
          />

          <Route
            path="/long-format"
            element={<Category pageHeading={"Long Format"} />}
          />

          <Route
            path="/:category/upcoming"
            element={
              <SubCategoryPage
                headingText="Upcoming"
                pageHeading="Shorts & documentaries > Upcoming"
              />
            }
          />
          <Route
            path="/:category/latest-release"
            element={
              <SubCategoryPage
                headingText="Latest Release"
                pageHeading="Shorts & documentaries > Latest-Release"
              />
            }
          />
          <Route
            path="/:category/all"
            element={
              <SubCategoryPage
                headingText="All Shorts & Documentaries"
                pageHeading="Shorts & documentaries > All "
              />
            }
          />
          <Route path="/services" element={<Services />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/founders" element={<Founder />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/clients" element={<Client />} />
          <Route path="/media" element={<Media />} />
          <Route path="/watch/:videoId" element={<VideoPage />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
        {!isVideoPage && <Footer />}
      </div>
    </>
  );
}

export default App;
