import React from "react";
import group from "./img/gourp.png"; // Ensure this path is correct
import Image32 from "./img/Image 32.png";
import FounderPersin from "./FounderPerson";
import Heading from "../../components/Highlightedheading/Heading";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";

const Founder = () => {
  return (
    <div>
      <div className="relative md:h-[92vh] h-[80vh] w-full text-white flex items-center ">
        <div className="absolute inset-0  opacity-75"></div>
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${group})` }}
        ></div>
        <div className="absolute top-0 left-0 w-full  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="358"
            viewBox="0 0 1920 358"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="0.49"
                y1="0.884"
                x2="0.49"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopOpacity="0" />
                <stop offset="1" />
              </linearGradient>
            </defs>
            <rect
              id="Black_"
              data-name="Black "
              width="1920"
              height="358"
              opacity="0.85"
              fill="url(#linear-gradient)"
            />
          </svg>
        </div>
        <div className="relative z-10 flex flex-col md:flex-row justify-between w-full h-full px-4 lg:px-12 xl:px-16">
          <div className="flex pr-4 md:pl-36   flex-col justify-end md:justify-center items-center md:items-start w-full md:w-[40%] h-full">
            <Heading headingText={"FOUNDERS"} />
          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 md:h-[72.2vh]">
            <div className="pl-[5.63vw] flex pt-[15vh] items-center md:pt-[19.85vh]">
              <Pageheading pageHeading={"Founders"} icon={<RiHome2Line />} />
            </div>
          </div>
          <div className="flex justify-center items-center  md:w-[60%] pt-10 md:pt-20  md:pr-16 lg:!overflow-y-hidden">
            <img
              src={Image32}
              alt="Founders"
              className="max-w-full w-[85.47vw] h-[32.24vh] md-full md:h-auto"
            />
          </div>
        </div>
      </div>

      <FounderPersin
        heading={true}
        flag={false}
        fName={"Ruchi"}
        lName={"Narain"}
        position={"( Founder )"}
        shortText={
          "Ruchi Narain is a distinguished and Multi-award winning Writer, Director, and Producer, who thrives on the challenge of straddling a variety of genres across formats. From feature films to gripping series, advertising commercials to documentaries, she’s done it all!"
        }
        fullText1={
          "Originally hailing from Delhi, she had a whirlwind of global experiences, hopping from Dubai and Muscat to Colombo and Doha. This jet-setting upbringing, led to her collecting schools as if they were Pokemon’s, a total of 12, including Woodstock in Mussoorie and USC! Now, she's proudly embracing her identity as a Bombayite, having called the city home for several years."
        }
        fullText2={
          "Kickstarting her filmmaking journey in 1996, she later won the Filmfare Award for her screenplay for Hazaaron Khwahishein Aisi (2003). Her directorial debut, KAL (Yesterday & Tomorrow), received the Osian’s Cinefan Critic’s Choice Award. She was the writer and director of the critically acclaimed Netflix thriller Guilty (2020), produced by Karan Johar’s Dharmatic Entertainment, starring Kiara Advani, Gurfateh Pirzada, and Taher Shabbir."
        }
        fullText3={
          "She wrote and directed Hanuman Da Damdaar (2017), India's largest animation release yet, produced by R.A.T Films and RNB Films, featuring Salman Khan as Hanuman. Narain co-created and co-directed Hundred (2020), an 8-part comedy series starring Lara Dutta and Rinku Rajguru for Disney Hotstar. The tryst with Disney Hotstar continued as writer, director, and showrunner for Karmma Calling (2024), the Indian adaptation of ABC’s Revenge, starring Raveena Tandon, Varun Sood, and Namrata Sheth"
        }
        fullText4={
          "Her versatility extends to directing ground-breaking ad films. Narain pioneered the social experiment format with her multi-award-winning film ‘The Divide’ on financial literacy for women, created for PayTM, which became a viral sensation."
        }
        fullText5={
          "From FMCG, beauty, appliances, and automobiles, she has directed numerous commercials, of which, the most memorable ones include Magic Bricks, JK Tyres, Lloyds, and Kellogg’s. She led a successful multi-starrer campaign for the National Literacy Mission. Testament to her success is evidenced by how she’s never had only a one-time collaboration with an agency or client, as they repeatedly rely on her to create impactful campaigns."
        }
        fullText6={
          "Beyond her impressive filmography, she has produced projects for emerging directors,  award-winning wildlife documentaries for Discovery Channel, short films, music videos, and commercials"
        }
        fullText7={
          "Off-set, Narain loves spending quality time with her young daughter Vira, who's in primary school. You might spot her with her nose in a book, swimming laps, indulging her passion for horse riding or her culinary prowess. If you're lucky, you might even catch her serenading friends and family with her beautiful voice, belting out her favourite tunes."
        }
        fullText8={"Phew! Talk about being multitalented!"}
      />

      <div className="flex items-center justify-end">
        <div className="border-[1px] opacity-[15%] border-[#ffff]  md:w-[75%] md:ml-auto"></div>
      </div>

      <FounderPersin
        heading={false}
        flag={true}
        fName={"Tahir"}
        lName={"Shabbir"}
        position={"( Co Founder )"}
        shortText={
          "As co-founder of RAT Films, Shabbir has produced several groundbreaking brand commercials, web series and feature films, including an adaptation of the American series Revenge for Disney Hotstar called Karmma Calling (2024) starring Raveena Tandon, Varun Sood and Namrata Sheth. He co-created and co-directed Hundred an 8-part comedy series starring Lara Dutta and Rinku Rajguru, in 2020 as well as produced the popular animated feature Hanuman Da Dumdaar (2017)."
        }
        fullText1={
          "Taher Shabbir is an Actor, Director, Producer, and Writer—a one-man cinematic powerhouse!"
        }
        fullText2={
          "As co-founder of RAT Films, Shabbir has produced several groundbreaking brand commercials, web series and feature films, including an adaptation of the American series Revenge for Disney Hotstar called Karmma Calling (2024) starring Raveena Tandon, Varun Sood and Namrata Sheth. He co-created and co-directed Hundred an 8-part comedy series starring Lara Dutta and Rinku Rajguru, in 2020 as well as produced the popular animated feature Hanuman Da Dumdaar (2017). "
        }
        fullText3={
          "As an actor, Taher charmed audiences as the slick lawyer Danish Ali Baig in Netflix's thriller Guilty (2020), opposite Kiara Advani. He played the sinister villain Naman Rana in Disney Hotstar’s Kaala (2023). He continued to win hearts with roles in series, commercials, and films and also garnered much praise and affection for his portrayal of Jai in Naam Shabana (2017), and Sangram Singh in Manikarnika (2019) amongst many others. He also starred in the Cannes award-winning film Exchange Offer (2020). "
        }
        fullText4={
          "He began his cinematic journey alongside his good friend and now RAT Films co-founder, Ashutosh Shah. They plunged into the world of documentaries with a college project on Naxalism, and their debut swept up awards at multiple festivals, marking the beginning of their legendary partnership, and later collaborating with acclaimed filmmakers like Madhur Bhandarkar and Sudhir Mishra, contributing to several feature films. He transitioned into major commercial projects as the first AD and Second Unit Directors on Bollywood blockbusters such as Once Upon a Time in Mumbai, directed by Milan Luthria and produced by Balaji Films, and Karan Johar's epic Agneepath. He, along with Ashutosh, soon took on the role of executive producer for director Sudhir Mishra. "
        }
        fullText5={
          "His directorial debut was nothing short of spectacular, with the public service music video Shiksha ka Suraj. This video, featuring Bollywood icons like Shah Rukh Khan, Kareena Kapoor, Priyanka Chopra, Anil Kapoor, Farhan Akhtar, and Shabana Azmi, was hailed by the press as the Mile Sur of this Millennium and became a viral sensation. Following this triumph, Taher co-directed the Skoda Prize Film for the Indian Contemporary Art Prize in 2012. Throughout his career, he has also collaborated with advertising legends such as Prakash Varma, Uzer Khan, Amit Sharma, Vinil Mathew, Farouk Al Joffrey, and a slew of international ad directors."
        }
        fullText6={
          "When he's not on set, you may find him indulging in his love for ghazals, sharing his passion for Shayari, or strumming a few tunes on his guitar. "
        }
        fullText7={"Taher Shabbir: the man who can do it all and then some!"}
      />

      <div className=" border-[1px] opacity-[15%] border-[#ffff]  md:w-[75%] md:mr-auto"></div>
      <FounderPersin
        heading={false}
        flag={false}
        fName={"Ashutosh"}
        lName={"Shah"}
        position={"( Director )"}
        shortText={
          "Ashutosh Shah, director, producer, and co-founder of R.A.T Films, known for seamlessly blending creativity with impact across genres and formats. Raised in Mumbai, Ashutosh’s fascination with the arts began young, inspiring him to dive into dramatics, painting, and directing in his school days, later strengthened by his education at Narsee Monjee College and KC College, where he specialized in Mass Media."
        }
        fullText1={
          "Ashutosh Shah, director, producer, and co-founder of R.A.T Films, known for seamlessly blending creativity with impact across genres and formats. Raised in Mumbai, Ashutosh’s fascination with the arts began young, inspiring him to dive into dramatics, painting, and directing in his school days, later strengthened by his education at Narsee Monjee College and KC College, where he specialized in Mass Media."
        }
        fullText2={
          "Ashutosh’s cinematic journey was propelled by the mentorship of industry giants like Santosh Sivan, who opened his eyes to the world of photography and the magic of motion pictures. His directorial debut a hard-hitting documentary on Naxalism—earned accolades and international recognition, setting him on a fast track in his  Bollywood journey. "
        }
        fullText3={
          "Since then, Ashutosh has made his mark with standout projects. His directorial debut in the public service music video  Shiksha Ka Suraj , featuring Shah Rukh Khan, Kareena Kapoor, Priyanka Chopra, and Anil Kapoor, was hailed as the Mile Sur of this Millennium and became an instant hit. His filmography is as versatile as it is impressive, with key roles in Khoya Khoya Chand,Once Upon a Time in Mumbai,Agneepath, and as 2nd Unit Director on Bhaag Milkha Bhaag,Guilty, and Fitoor. R.A.T Films, founded with Ruchi Narain and Taher Shabbir, has become synonymous with high-quality, impactful projects. The company’s animated hit  Hanuman Da Damdaar , voiced by Salman Khan and other Bollywood stars, became India’s largest animated release, while Ashutosh’s foray into OTT with Disney+ Hotstar’s  Hundred  saw him co-create, direct, and produce a comedy series starring Lara Dutta and Rinku Rajguru. His recent work,  Karmma Calling , the Indian adaptation of  Revenge , brings him full circle with Disney Hotstar, led by Raveena Tandon."
        }
        fullText4={
          "Ashutosh Shah’s advertising journey began as the go-to 1st assistant for industry giants like Prakash Varma and numerous international directors, gaining hands-on experience across over 400 high-impact commercials. Since then, he’s taken his expertise to the next level, producing over 100 ads through his production house, R.A.T Films for various leading brands. One of his standout projects, The Divide, became a viral sensation, sparking conversations around women’s financial literacy. As a  advertising director Ashutosh’s work features many Bollywood Superstars and cool comic campaign for FMCG brands."
        }
        fullText5={
          "Ashutosh’s dual expertise as a producer and director gives him a unique edge in delivering story-driven content with precision and impact. Today, Ashutosh Shah is celebrated as a young producer who combines dedication with a bold vision, inspiring his industry peers and constantly pushing the boundaries of filmmaking."
        }
        fullText6={
          "Beyond the set, Ashutosh is a modern explorer—sampling the latest food trends, experimenting with new tech, and enjoying time with his dog and twin daughters. A foodie who’s mastered healthy eating, a tech enthusiast, and a music lover with a passion for drums, Ashutosh Shah is always seeking new ways to stay inspired, blending art and impact in everything he creates. "
        }
      />
    </div>
  );
};

export default Founder;
