import Aos from "aos";
import React, { useEffect } from "react";

const SectionHeading = ({ sectionheading, CustomCss = "", onClick }) => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
      delay: 200,
    });
  }, []);

  return (
    <h2
      className={`text-white text-[12.8vw] md:text-[3.54vw]  font-bebas_neue  leading-none tracking-normal uppercase brightness-125 ${CustomCss}`}
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
      onClick={onClick}
    >
      {sectionheading}
    </h2>
  );
};

export default SectionHeading;
