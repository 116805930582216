import Aos from "aos";
import React, { useEffect } from "react";

const Mediasection = ({
  imgSrc,
  leftMediaText,
  rightMediaText,
  reverse,
  customCSs,
}) => { 

  useEffect(() => {
    Aos.init({ 
      easing: 'ease-in', 
      duration: 1500,
      once: false,
     
    });
  }, []);


  return (
    <div
      className={`w-full   py-10 lg:pr-[16.30vw] md:pl-[11.67vw] 2xl:pr-[13rem] relative ${customCSs}`} 
      data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
    >
      <div
        className={` w-full  flex flex-col md:flex-row  items-center justify-center px-[4vh]  ${
          reverse ? "flex-row-reverse" : "flex-row"
        }`}
      >
        {/* Left side */}
        <div className="outer-scratch md:w-[32.08vw] w-[91.47vw]  md:h-[25.70vh] flex flex-col items-center justify-center bg_group_1606  py-8 ">
          <div className="background grain">
            <div className="flex items-center justify-center">
              <img
                src={imgSrc}
                alt=""
                className=" md:w-[5.36vw] w-[21.33vw] object-contain p-2"
              />
            </div>
            <p className="w-[90vw] lg:w-[18.22vw] h-auto lg:h-[5.6875rem] text-center text-white opacity-100 font-bebas_neue md:text-[2.08vw] text-[8.53vw] tracking-normal md:leading-[2.50vw]  leading-[10.67vw]">
              {leftMediaText}
            </p>
          </div>
        </div>

        {/* Right side */}
        <div className="px-4 md:px-0 py-[32px] w-[91.47vw] h-auto md:w-[39.96vw] md:h-[25.68vh] flex flex-col  justify-center md:py-4 border-[1px] border-gray-100 border-solid border-opacity-30">
          <p className="text-left font-light md:text-[1.15vw] text-[5.33vw]   tracking-normal font-montserrat text-gray-100  md:pr-[18vh] md:pl-[3.5625rem] overflow-hidden">
            {rightMediaText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mediasection;
