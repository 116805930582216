import { BsArrowLeft, BsArrowRight } from "react-icons/bs"; 
import { BsArrow90DegLeft } from "react-icons/bs"; 
import { BiRightArrowAlt } from "react-icons/bi"; 
import { BiLeftArrowAlt } from "react-icons/bi"; 
import React, { useEffect, useState } from "react";
import Heading from "../Highlightedheading/Heading";
import Button from "../Buttons/Button";
import RoundedShadow from "../../assets/Shortfilms/Group 1697.png";
import Rounded from "../../assets/Shortfilms/pexels-photo-102129.png";

import img1 from "../../assets/Shortfilms/1.png";
import SwiperSlider from "../SwiperSlider/SwiperSlider";
import { useNavigate } from "react-router-dom";

const ShortFilms = () => { 
  const navigate=useNavigate();

  const dummyData = [
    {
      id: 1,
      headingText: "WEB SERIES ",
      //   backgroundImage: Web_img,
      imageUrl: img1,

      releaseInfo: {
        year: "2024",
        season: "1 Season",
        languages: "7 Languages",
        rating: "U/A 16+",
      },
      description:
        "Karma Talwar's entry into the elite Alibaug circle disturbs high-society queen Indrani Kothari. It soon becomes clear that Karma has a dark past. She is on a mission and has her first target in sight.",
      genres: ["Drama", "Thriller"],
      buttonText: "WATCH TRAILER", 
      videoId:293940724,
    },  
   
    {
      id: 2,
      headingText: "DOCUMENTARY",
      imageUrl: img1,
      releaseInfo: {
        year: "2023",
        season: "1 Season",
        languages: "5 Languages",
        rating: "U/A 13+",
      },
      description:
        "Explore the hidden wonders of the ocean, revealing the beauty and mysteries that lie beneath the waves.Explore the hidden wonders of the ocean, revealing the beauty and mysteries that lie beneath the waves.",
      genres: ["Documentary", "Nature"],
      buttonText: "WATCH NOW", 
      videoId:333499420,
    },
    {
      id: 3,
      headingText: "MOVIE",
      imageUrl: img1,
      releaseInfo: {
        year: "2022",
        season: "2 Seasons",
        languages: "3 Languages",
        rating: "U/A 18+",
      },
      description:
        "A gripping tale of survival and friendship in the harshest conditions imaginable.A gripping tale of survival and friendship in the harshest conditions imaginable.",
      genres: ["Adventure", "Drama"],
      buttonText: "WATCH MOVIE",
      videoId: 291681025
    },
    {
      id: 4,
      headingText: "ANIMATION",
      imageUrl: img1,
      releaseInfo: {
        year: "2021",
        season: "3 Seasons",
        languages: "4 Languages",
        rating: "U/A 7+",
      },
      description:
        "Join the whimsical characters on an epic journey through a fantastical world.Join the whimsical characters on an epic journey through a fantastical world.",
      genres: ["Animation", "Fantasy"],
      buttonText: "WATCH EPISODES",
      videoId: 293940724
    },
    {
      id: 5,
      headingText: "WEB SERIES",
      imageUrl: img1,
      releaseInfo: {
        year: "2021",
        season: "3 Seasons",
        languages: "4 Languages",
        rating: "U/A 7+",
      },
      description:
        "Join the whimsical characters on an epic journey through a fantastical world.Join the whimsical characters on an epic journey through a fantastical world.",
      genres: ["Animation", "Fantasy"],
      buttonText: "WATCH EPISODES",
      videoId:276703567,
    },
    {
      id: 6,
      headingText: "DOCUMENTARY",
      imageUrl: img1,
      releaseInfo: {
        year: "2023",
        season: "1 Season",
        languages: "5 Languages",
        rating: "U/A 13+",
      },
      description:
        "Explore the hidden wonders of the ocean, revealing the beauty and mysteries that lie beneath the waves.Explore the hidden wonders of the ocean, revealing the beauty and mysteries that lie beneath the waves.",
      genres: ["Documentary", "Nature"],
      buttonText: "WATCH NOW",
      videoId: 123456789
    },
    {
      id: 7,
      headingText: "MOVIE",
      imageUrl: img1,
      releaseInfo: {
        year: "2022",
        season: "2 Seasons",
        languages: "3 Languages",
        rating: "U/A 18+",
      },
      description:
        "A gripping tale of survival and friendship in the harshest conditions imaginable.A gripping tale of survival and friendship in the harshest conditions imaginable.",
      genres: ["Adventure", "Drama"],
      buttonText: "WATCH MOVIE",
      videoId: 987654321
    },
  ]; 

 
  const [content, setContent] = useState(dummyData[0]);
  const images = [img1, img1, img1, img1, img1, img1, img1];
  
function handleWatchButton(id){
   navigate(`/watch/${id}`);
} 



  return (
    <section className="w-full h-full  ">
      <div className="flex items-center justify-between z-30  md:px-[7.29vw]  px-4 md:pt-[4.7vw] py-6   relative ">
        <Heading headingText="SHORTS FILMS" />
        <Button buttonText={"VIEW ALL"} onClick={()=>navigate("/short-documentaries")}/>
      </div>

      <div className="flex sm:flex-row  flex-col-reverse items-center justify-center md:px-[9.9vw] px-4  md:gap-y-[1.875vw]"
      
      >
        <div className=" absolute left-[-520px] ">
          <img src="images/Ellipse 63.png" alt="" />
        </div>
     
        <div className="w-full flex flex-col items-start z-10 md:gap-y-0 gap-y-[1.85vh]"
         data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <h2 className="text-white md:text-[4.69vw] text-[10.67vw]  font-bebas_neue tracking-normal md:leading-[8.33vh]  uppercase brightness-125">
            {content.headingText}
          </h2>
          <div className="flex flex-wrap items-center space-x-2 md:space-x-4 text-white  font-semibold brightness-125 md:leading-[3.15vh] md:pt-[2.17vh] font-montserrat">
            <div className="flex items-center justify-center  opacity-100 brightness-125">
              <span className="md:text-[1.25vw] font-semibold opacity-100">
                {content.releaseInfo.year}
              </span>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <span className="w-2 h-2 rounded-full bg-white opacity-50"></span>
              <span className="md:text-[1.25vw] font-semibold opacity-100">
                {content.releaseInfo.season}
              </span>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <span className="w-2 h-2 rounded-full bg-white  opacity-50 "></span>
              <span className="md:text-[1.25vw] font-semibold opacity-100">
                {content.releaseInfo.languages}
              </span>
            </div>

            <div className="flex items-center justify-center space-x-2  bg-[#36c31f69]  rounded-md px-2">
              <span className="w-2 h-2 rounded-full bg-white  opacity-50"></span>
              <span className="text-white brightness-200 md:text-[1.25vw] opacity-100 font-semibold">
                {content.releaseInfo.rating}
              </span>
            </div>
          </div>

          <p className=" whitespace-pre-line tracking-normal font-montserrat md:text-[1.25vw] text-white opacity-100 font-normal  md:pt-[4.11vh] md:w-[30.55vw] ">
            {content.description}
          </p>
          <div className="flex items-center space-x-2 text-white md:text-[1.25vw] md:pt-[3.53vh] md:leading-[3.15vh] font-montserrat font-medium opacity-100 brightness-125">
            {content.genres.map((genre, index) => (
              <React.Fragment key={index}>
                <span>{genre}</span>
                {index < content.genres.length - 1 && (
                  <div className="w-[2px] h-6 bg-white"></div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="border-[1px] border-white md:px-[1.30vw] md:py-[2.78vh] px-3 p-3 md:mt-[4.16vh] md:rounded-[3.40vw] rounded-[46px]  relative overflow-hidden group" 
          onClick={()=>handleWatchButton(content.videoId)}
          >
            <button className="flex items-center md:gap-x-3 gap-x-2 text-white uppercase font-montserrat leading-0 text-16 md:text-[1.25vw] font-semibold opacity-100 relative z-10">
              {content.buttonText}

              <div className="flex items-center justify-center bg-[#4AB66C] rounded-full p-[2px] transition-colors duration-300 ease-in-out group-hover:bg-white">
                <BiRightArrowAlt className="text-white md:w-[2.60417vw] w-[6.5vw] h-auto  transition-transform duration-300 ease-in-out group-hover:text-[#22AD86] -rotate-45 group-hover:rotate-0" />
              </div>
            </button>
            <div className="absolute inset-0 bg-custom-vertical-gradient transform translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
          </div>
        </div>

        <div className="relative w-full  h-full flex items-center justify-center md:p-[3.125vw] p-[5vw]" 
        data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        
        >
          <div className="flex items-center justify-center z-10 object-blur drop-shadow-2xl relative md:scale-150 scale-125"
           
          >
            <img
              src={RoundedShadow}
              alt="shadow"
              className="w-full h-full object-cover"
            />
            <div className="absolute scale-75 z-20 ">
              <img
                src={Rounded}
                alt="shadow"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute z-40 flex items-center justify-center  scale-90 ">
              <SwiperSlider
                images={images}
                dummyData={dummyData}
                setContent={setContent}
              />
            </div> 

          
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShortFilms;
