import Aos from "aos";
import React, { useEffect } from "react";

const Heading = ({ headingText, className="" }) => { 
  useEffect(() => {
    Aos.init({
      duration: 1500, // Duration of animation (1.5 seconds)
      once: false, // Animation should happen every time the section enters the viewport
    });
  }, []);
  return (
    <h1 className={`gradient-text  md:text-[5.5vw] text-[11vw] flex flex-wrap  text-center tracking-normal brightness-125    font-bebas_neue  uppercase  ${className}`} 
     data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
    >
      {headingText}
    </h1>
  );
};

export default Heading;
