import React, { useEffect } from "react";
import AboutPageHeading from "./AboutPageHeading";
import MultipleRows from "./MultipleRows";
import Aos from "aos";
export const Section4 = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className=" md:pl-[6.77vw] md:pr-[4.58vw]">
      <div className="md:py-[5.56vh] ">
        <AboutPageHeading headingText="Our Partners" />
      </div>

      <div
        className=" "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <MultipleRows />
      </div>
    </div>
  );
};

export default Section4;
