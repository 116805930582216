import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export function Responsive({ images, fName, lName, position }) { 



  
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-next-arrow absolute right-0 md:-right-10 top-1/2 opacity-80 text-[8vw] md:text-[2.5vw] transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <FiChevronRight  color="#ffff" /> {/* Custom right icon */}
    </div>
  );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-prev-arrow absolute left-0 md:-left-10  top-1/2 text-[8vw] md:text-[2.5vw] opacity-80 transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <FiChevronLeft  color="#ffff" />
    </div>
  );
};
  const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 4, 
    slidesToScroll: 4, 
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />, 
    autoplay: true, 
    autoplaySpeed: 5000, 
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="px-[3vw] md:p-0">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            className="group flex relative justify-center items-center px-[1vw]   "
          >
            {/* Image with grayscale filter applied by default */}
            <img
              src={image}
              alt={`Slide ${index}`}
              className="h-[28.34vh] cursor-pointer w-[38.67vw] md:h-[58.8vh] md:w-[20.94vw] object-fill grayscale group-hover:grayscale-0 transition duration-500 ease-in-out"
            />
            {/* Content (Name and Position) initially hidden and shown on hover */}
            <div className="absolute bottom-10 left-10 z-20  opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out shadow-sm ">
              <h3 className="gradient-text  md:text-[2.25vw] tracking-normal font-bebas_neue uppercase whitespace-nowrap">
                {fName}
                <br />
                {lName}
              </h3>
              <p className=" font-montserrat opacity-100 text-white font-normal md:text-[1.04vw]">
                {position}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Responsive;
