import React, { useEffect, useState } from "react";
import Heading from "../Highlightedheading/Heading";
import BasicTextFields from "../textfield/textField";
import toast, { Toaster } from "react-hot-toast";
import Button from "../Buttons/Button";
import Aos from "aos";

const Contact = () => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = (values) => {
    let errors = {};

    // Full Name Validation
    if (!values.fullName.match(/^[a-zA-Z\s]{3,}$/)) {
      errors.fullName = "Please enter a valid name.";
    }

    // Email Validation
    if (!values.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      errors.email = "Please enter a valid email address.";
    }

    // Phone Number Validation
    if (!values.phoneNumber.match(/^[6789]\d{9}$/)) {
      errors.phoneNumber =
        "Please enter a valid phone number.";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formValues);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // No errors, show success message
      toast.success("Form submitted successfully!");

      // Clear form after submission
      setFormValues({
        fullName: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    }
  }; 

  useEffect(() => {
    Aos.init({ 
      easing: 'ease-in', 
      duration: 1500,
      once: false,
      delay: 200,
    });
  }, []);

  return (
    <div className="md:px-[7.29vw]  px-4 md:pt-[13.7vh] pb-0   py-[5.56vh] md:pb-[9.17vh] rounded-lg w-full h-full flex flex-col font-montserrat font-light text-white relative ">
      <Toaster position="top-right" />
      <div>
        <Heading
          headingText={"CONTACT US"}
          className="text-center md:text-left"
        />
      </div>

       
      <form onSubmit={handleSubmit} className="space-y-6 md:space-y-16"
      data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
      >
        <div className="flex flex-col space-y-6  md:flex-row text-[0.83vw] md:text-[1.25vw] justify-between items-center md:gap-10 md:space-y-0 md:space-x-4">
          <div className="w-full">
            <BasicTextFields
              name="fullName"
              value={formValues.fullName}
              onChange={handleChange}
              text="Name*"
            />
            {errors.fullName && (
              <p className="text-red-500 text-[3.5vw] md:text-[1.1vw] p-1">{errors.fullName}</p>
            )}
          </div>
          <div className="w-full">
            <BasicTextFields
              name="email"
              value={formValues.email}
              onChange={handleChange}
              text="Email Id*"
            />
            {errors.email && (
              <p className="text-red-500 text-[3.5vw] md:text-[1.1vw] p-2">{errors.email}</p>
            )}
          </div>
          <div className="w-full">
            <BasicTextFields
              name="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleChange}
              text="Contact No*"
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-[3.5vw] md:text-[1vw] p-1">{errors.phoneNumber}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-28">
          <div className="w-full space-y-6">
            <BasicTextFields
              name="message"
              value={formValues.message}
              onChange={handleChange}
              text="Message"
              fullWidth={true}
            />
            {/* {errors.message && (
              <p className="text-red-500 text-md p-1">{errors.message}</p>
            )} */}
          </div>
          <Button buttonText={"Submit"} customCss={"pt-[2vh] pl-2"} />
        </div>
      </form>
    </div>
  );
};

export default Contact;
