import { BiArrowBack } from "react-icons/bi"; 
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BiLeftArrowAlt } from "react-icons/bi"; // Import left arrow icon

const Watch = () => {
  const { videoId } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate

  // Construct the Vimeo embed URL with parameters to hide like, share, and watch later options
  const videoUrl = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&dnt=1&controls=1&transparent=0&autoplay=1&muted=0&buttons.like=0&buttons.watchlater=0&buttons.share=0`;

  const handleBackClick = () => {
    navigate(-1); // Navigate back
  };

  return (
    <div className="relative w-screen h-screen overflow-hidden bg-black">
      <button
        type="button"
        className={`flex items-center md:gap-x-[0.83vw] gap-x-3 cursor-pointer relative overflow-visible group absolute top-4 left-4 z-10`}
        onClick={handleBackClick}
      >
        <BiArrowBack
          className="text-white md:w-[2.60vw] w-[6.5vw] h-auto transition-transform duration-300 ease-in-out"
        />
        
      </button>
      <iframe
        src={videoUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{ position: "absolute", top: 0, left: 0 }}
        title="Vimeo Player"
      ></iframe>
    </div>
  );
};

export default Watch;
