// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; 

// eslint-disable-next-line react/prop-types
const SubCategoryItem = ({ item }) => {   
  const navigate = useNavigate();  
  
  
  return ( 
    <div 
      className="md:w-[37.96vh] md:h-[46.23vh] w-full h-full transform transition-transform duration-300 ease-linear hover:scale-110 hover:z-20"
      onClick={() => navigate("/short-documentaries/details/290693752")}  
    >
      <img
        src={`/Upcoming_img/${item}.png`}
        alt=""
        className="w-full h-full object-cover" 
        loading="lazy"
      />
    </div>
  );
};

export default SubCategoryItem;
