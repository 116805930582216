import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgimg from '../../assets/AboutUs/film_texture___grain_explosion_by_stephanepellennec_d37pwfa-fullview.png';
import img1 from '../../assets/AboutUs/Group 1716.png';
import img2 from '../../assets/AboutUs/Group 1717.png';
import img3 from '../../assets/AboutUs/Group 1718.png';
import img4 from '../../assets/AboutUs/Group 1719.png';
import img5 from '../../assets/AboutUs/Group 1720.png';
import img6 from '../../assets/AboutUs/Group 1721.png';
import img7 from '../../assets/AboutUs/Group 1722.png';
import img8 from '../../assets/AboutUs/Group 1723.png';
import img9 from '../../assets/AboutUs/Group 1724.png';
import img10 from '../../assets/AboutUs/Group 1725.png';
import img11 from '../../assets/AboutUs/Group 1726.png';
import img12 from '../../assets/AboutUs/Group 1727.png';
import img13 from '../../assets/AboutUs/Group 1728.png';
import img14 from '../../assets/AboutUs/Group 1729.png';
import img15 from '../../assets/AboutUs/Group 1730.png';
import img16 from '../../assets/AboutUs/Group 1731.png';
import img17 from '../../assets/AboutUs/Group 1732.png';
import img18 from '../../assets/AboutUs/Group 1733.png';
import img19 from '../../assets/AboutUs/Group 1734.png';
import img20 from '../../assets/AboutUs/Group 1735.png';
import img21 from '../../assets/AboutUs/Group 1736.png';
import img22 from '../../assets/AboutUs/Group 1737.png';
import img23 from '../../assets/AboutUs/Group 1738.png';
import img24 from '../../assets/AboutUs/Group 1739.png';
// import img25 from '../../assets/AboutUs/Group 1740.png';

const images = [
  img1, img2, img3, img4, img5, img6, img7, img8, 
  img9, img10, img11, img12, img13, img14, img15, img16, 
  img17, img18, img19, img20, img21, img22, img23, img24
];

export function ClientSection2() {
  const [gap, setGap] = useState(40); // Default gap

  // Update gap based on window size
  useEffect(() => {
    const updateGap = () => {
      if (window.innerWidth < 480) {
        setGap(1); // Smaller gap on extra small screens
      } else if (window.innerWidth < 768) {
        setGap(1); // Medium gap on small screens
      } else if (window.innerWidth < 1280) {
        setGap(20); // Larger gap on medium screens
      } else {
        setGap(40); // Default gap on large screens
      }
    };

    updateGap(); // Set initial gap
    window.addEventListener("resize", updateGap); // Update gap on resize

    // Cleanup listener
    return () => {
      window.removeEventListener("resize", updateGap);
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,  // 4 columns on large screens
    slidesToScroll: 4, // Scroll 4 at a time
    rows: 4,          // 4 rows
    slidesPerRow: 1,  // 1 slide per row
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,  // 3 columns on medium screens
          slidesToScroll: 3,
          rows: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,  // 2 columns on small screens
          slidesToScroll: 2,
          rows: 6,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,  // 1 column on extra small screens
          slidesToScroll: 2,
          rows: 6, 
        }
      }
    ]
  };

  return (
    <div className="px-4 py-4">
      <Slider
        {...settings}
        style={{
          margin: `0 -${gap}px`,  // Responsive margin for .slick-list
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              padding: `0 ${gap}px`,  // Responsive padding for .slick-slide
            }}
            className="flex justify-center items-center p-[3vw] md:p-7"
          >
            <div
              className="w-[40.27vw] h-[18.93vh] lg:w-[20.68vw] lg:h-[26.11vh] flex justify-center items-center bg-cover bg-center overflow-hidden"
              style={{ backgroundImage: `url(${bgimg})` }}
            >
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-cover filter  transition duration-300 ease-in-out"
              />
            </div>
          </div>
        ))}
      </Slider> 
      <style jsx>{`
       
       .slick-prev, .slick-next {
         display: none !important; /* Hide arrows everywher */
       }
    
   `}</style>
    </div>
  );
}

export default ClientSection2;
