import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { EffectCards, Autoplay, Navigation } from "swiper/modules";

import "./styles.css";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

export default function SwiperSlide1({ images, dummyData, setContent }) {
  const swiperRef = useRef(null);

  function handleSlideChange(swiper) {
    const activeIndex = swiper.activeIndex;
    setContent(dummyData[activeIndex]);
  }

  function handlePrevClick() {
    if (swiperRef.current && swiperRef.current.slidePrev) {
      swiperRef.current.slidePrev();
    }
  }

  function handleNextClick() {
    if (swiperRef.current && swiperRef.current.slideNext) {
      swiperRef.current.slideNext();
    }
  }

  return (
    <div className="swiper-container">
      <Swiper
        effect={"cards"}
        grabCursor={true}
        centeredSlides={true}
        modules={[EffectCards, Autoplay, Navigation]}
        className="mySwiper"
        onSlideChange={handleSlideChange}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }} 
        speed={800}
        touchRatio={1.5}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img} alt={`Slide ${index}`} className="swiper-image" />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Custom Navigation Buttons with Icons */}
      <div className="hidden md:flex items-center justify-between absolute left-1/2 transform -translate-x-1/2 cursor-pointer bottom-[-4vw] text-white gap-x-[1.24vw]">
        <div onClick={handlePrevClick}>
          <BsArrowLeft size={30} className="text-white" />
        </div>
        <div onClick={handleNextClick}>
          <BsArrowRight size={30} className="text-white" />
        </div>
      </div>
    </div>
  );
}
