import Aos from 'aos';
import React, { useEffect } from 'react';

export const AboutPageHeading = ({ headingText }) => { 

   
  useEffect(() => {
    Aos.init({ 
      easing: 'ease-in', 
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className="text-left"
      data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
    >
      <h1 className="font-bebas text-white uppercase tracking-tight opacity-100 
        md:text-[3.54vw] text-[10vw] pt-[10vw] md:pt-0 pb-[7vw] md:pb-0 pl-[5vw] md:pl-0"
      >
        {headingText}
      </h1>
    </div>
  );
}

export default AboutPageHeading;
