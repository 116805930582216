// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import contactimg from "./img/contactimg.png";
import Heading from "../../components/Highlightedheading/Heading";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import Aos from "aos";

const Media = () => {
  const latestData = [
    {
      id: 1,
      img: "/media/latest-1.png",
      desc: "They say it’s the thought that counts… Happy Valentine’s Day",
      date: "15 Feb",
    },
    {
      id: 2,
      img: "/media/latest-1.png",
      desc: "They say it’s the thought that counts… Happy Valentine’s Day",
      date: "15 Feb",
    },
    {
      id: 3,
      img: "/media/latest-1.png",
      desc: "They say it’s the thought that counts… Happy Valentine’s Day",
      date: "15 Feb",
    },
    {
      id: 4,
      img: "/media/latest-1.png",
      desc: "They say it’s the thought that counts… Happy Valentine’s Day",
      date: "15 Feb",
    },
    {
      id: 5,
      img: "/media/latest-1.png",
      desc: "They say it’s the thought that counts… Happy Valentine’s Day",
      date: "15 Feb",
    },
    {
      id: 6,
      img: "/media/latest-1.png",
      desc: "They say it’s the thought that counts… Happy Valentine’s Day",
      date: "15 Feb",
    },
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className="w-full h-full  text-white relative">
      <div className="relative w-full h-[77vh] md:h-[72.7vh]   flex items-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10">
          <div className=" md:pl-[5.63vw] pl-[3vw] flex items-center md:pt-[21vh] pt-[14vh]">
            <Pageheading pageHeading={"Media"} icon={<RiHome2Line />} />
          </div>
        </div>
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center flex justify-center items-center"
          style={{ backgroundImage: `url(${contactimg})` }}
        >
          <Heading headingText={"Media"} />
        </div>
      </div>
      <section className="w-full md:pl-[6.77vw] md:pr-[4.79vw] px-4">
        <div className="md:pt-[4.69vw] md:pb-[3.13vw] pt-[16vw] pb-[10vw]">
          <SectionHeading sectionheading={"Trending Now"} />
        </div>

        <div
          className="grid md:grid-cols-[60%_40%]   md:pb-[3.13vw] gap-y-[7vw] md:gap-y-0 md:h-[35.42vw]  gap-[1.56vw]  grid-cols-1 w-full"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <div
            className="h-[120.53vw] md:h-full"
            style={{
              backgroundImage: `url(${
                window.innerWidth < 768
                  ? "/media/mobilemedia-1.png"
                  : "/media/media-1.png"
              })`,
            }}
          >
            <div className="flex items-end justify-end h-full w-full p-[3.13vw]">
              <div className="flex flex-col items-start justify-items-start gap-y-[1.56vw] font-montserrat">
                <div className="text-[#22AD86] font-semibold md:text-[1.15vw] text-[3.73vw]">
                  TRENDING
                </div>
                <div className="md:text-[1.56vw] text-[4.27vw] font-normal">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry Lorem Ipsum is simply text of the
                  printing and typesetting industry
                </div>
                <div className="md:text-[1.15vw] opacity-50 text-[3.73vw]">
                  15 Feb, 2024
                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-rows-2 md:gap-y-[1.56vw]  gap-y-[7vw] m grid-cols-1">
            <div
              className=""
              style={{
                backgroundImage: `url(${
                  window.innerWidth < 768
                    ? "/media/mobilemedia-2.png"
                    : "/media/media-2.png"
                })`,
              }}
            >
              <div className="flex items-end justify-end h-[86.67vw] md:h-full w-full p-[3.13vw] md:p-[1.24vw]">
                <div className="flex flex-col items-start justify-start gap-y-[0.625vw] font-montserrat">
                  <div className="text-[#22AD86] font-semibold md:text-[0.9375vw] text-[3.2vw]">
                    TRENDING
                  </div>
                  <div className="flex items-end justify-between">
                    <div className="md:text-[1.15vw] text-[3.7vw] font-light">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </div>
                    <div className="md:text-[0.9375vw]  text-[3.2vw] opacity-50 whitespace-nowrap">
                      15 Feb, 2024
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                backgroundImage: `url(${
                  window.innerWidth < 768
                    ? "/media/mobilemedia-3.png"
                    : "/media/media-3.png"
                })`,
              }}
            >
              <div className="flex items-end justify-end h-[86.67vw] md:h-full w-full p-[3.13vw] md:p-[1.24vw]">
                <div className="flex flex-col items-start justify-start gap-y-[0.625vw] font-montserrat">
                  <div className="text-[#22AD86] font-semibold md:text-[0.9375vw] text-[3.2vw]">
                    TRENDING
                  </div>
                  <div className="flex items-end justify-between">
                    <div className="md:text-[1.15vw] text-[3.7vw] font-light">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </div>
                    <div className="md:text-[0.9375vw]  text-[3.2vw] opacity-50 whitespace-nowrap">
                      15 Feb, 2024
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:pt-[4.69vw] md:pb-[3.13vw] pt-[16vw] pb-[10vw]">
          <SectionHeading sectionheading={"Latest"} />
        </div>

        <div
          className="grid md:grid-cols-3 grid-cols-1  gap-[3.125vw] w-full"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          {latestData.map((item) => (
            <div
              key={item.id}
              className="flex flex-row-reverse gap-y-[1.04vw] border-[1px] md:p-0 p-[3.2vw] border-solid border-[#FFFFFF] border-opacity-30 md:flex-col font-montserrat items-start "
            >
              <div className="md:h-[22.76vw] w-[38.47vw] md:w-full ">
                <img
                  src={item.img} // Use the img property from the current item
                  alt={`Latest media ${item.id}`}
                  className="w-full h-full object-cover"
                />
              </div>

              <div className="flex flex-col items-start justify-between h-full">
                <div className="text-[3.73vw]  md:text-[1.15vw] font-light  md:pl-[1.46vw]">
                  {item.desc}
                </div>
                <div className="text-[4.27vw] md:text-[0.9375vw] text-left md:text-right w-full opacity-50 whitespace-nowrap md:pr-[2.29vw] md:pb-[1.04vw]">
                  {item.date}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Media;
