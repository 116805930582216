import React, { useEffect } from "react";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import Centerheading from "../../components/services/Centerheading";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import contactImg from "../career/img/career.png";
import Heading from "../../components/Highlightedheading/Heading";
import GridImage from "../../components/GridImage";
import Aos from "aos";
const Gallery = () => {
  const galleryImage = [2, 8, 4, 6, 2, 8, 2, 6, 4, 6, 6, 8, 6, 4, 6, 8];

  const imageChunks = [];
  for (let i = 0; i < galleryImage.length; i += 4) {
    imageChunks.push(galleryImage.slice(i, i + 4));
  }

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <>
      <div
        className="w-full h-full bg-no-repeat bg-cover bg-center relative"
        style={{ backgroundImage: `url(${contactImg})` }}
      >
        <div className="relative w-full h-[77vh] md:h-[72.7vh] flex items-center overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10">
            <div className=" md:pl-[5.63vw] pl-[3vw] flex items-center md:pt-[21vh] pt-[14vh]">
              <Pageheading pageHeading={"Gallery"} icon={<RiHome2Line />} />
            </div>
          </div>
          <div
            className="absolute inset-0 bg-cover bg-no-repeat bg-center flex justify-center items-center z-0"
            // style={{ backgroundImage: `url(${contactImg})` }}
          >
            <Heading headingText={"GALLERY"} />
          </div>
        </div>
      </div>

      {/* Section for heading and gallery */}
      <div className="w-full h-full   ">
        <div className="md:pl-[6.77vw] md:pt-[8.33vh] pt-[5.56vh] md:pb-[3.56vh] px-4 md:px-0">
          <SectionHeading sectionheading={"DISCOVER OUR HIGHLIGHTED GALLERY"} />
        </div>
      </div>

      <div
        className="w-full h-full md:px-[4.17vw] px-4 py-[3.7vh] md:py-0"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {imageChunks.map((chunk, index) => (
          <GridImage key={index} images={chunk} />
        ))}
      </div>
    </>
  );
};

export default Gallery;
