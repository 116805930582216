/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="px-4 md:px-0 pt-[6vh] md:pt-0 lg:mx-auto text-white relative">
      <div className="w-full flex flex-col lg:flex-row justify-between lg:px-[7.29vw] lg:pt-[9.26vh]">
        <div className="flex flex-col mb-6 lg:mb-0">
          <div
            className="hidden lg:block cursor-pointer items-start"
            onClick={scrollToTop}
          >
            <img
              alt="RAT Films Logo"
              src="/logo/Logo.png"
              className="w-[11.98vw]"
            />
          </div>
          <Link
            to="https://www.google.com/maps/place/305+Suvaas,+15th+Road,+Near+Red+Chillies+Entertainment/@somecoordinates"
            target="_blank"
            className="opacity-100 text-[4.7vw] lg:text-[1.25vw] md:pt-[4vh] text-white font-montserrat font-normal hover:opacity-50 transition-opacity duration-300"
          >
            305 Suvaas, 15th Road,
            <br />
            Near Red Chillies
            <br />
            Entertainment/Starbucks
            <br />
            Santacruz West, Mumbai.
          </Link>
        </div>
        <div className="w-full lg:w-[17.23vw] flex flex-col lg:mb-0 py-[3.70vh] md:py-0 lg:mt-[3.96vh]">
          <h2 className="text-[5.33vw] lg:text-[1.25vw] font-semibold text-[#22AD86] font-montserrat">
            MORE INFORMATION
          </h2>
          <ul className="lg:mt-[4.44vh] mt-[3vh] text-[#FFFFFF] font-montserrat font-normal gap-y-[24px] text-[4.7vw] lg:text-[1.25vw]">
            <li>
              <Link
                to="/about-us"
                className="hover:opacity-50 transition-opacity duration-300"
              >
                About Us
              </Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link
                to="/services"
                className="hover:opacity-50 transition-opacity duration-300"
              >
                Services
              </Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link
                to="/media"
                className="hover:opacity-50 transition-opacity duration-300"
              >
                Media
              </Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link
                to="/clients"
                className="hover:opacity-50 transition-opacity duration-300"
              >
                Clients
              </Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link
                to="/careers"
                className="hover:opacity-50 transition-opacity duration-300"
              >
                Careers
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col lg:my-0 lg:mt-[3.96vh] lg:pr-[6.55vw]">
          <h2 className="text-[5.3vw] lg:text-[1.25vw] font-semibold font-montserrat text-[#22AD86]">
            GET IN TOUCH
          </h2>

          <div className="flex mt-[3vh] lg:mt-[4.44vh] space-x-6">
            <a
              href="https://www.youtube.com/channel/YOUR_CHANNEL"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white items-center md:mt-[0.5vw] hover:opacity-50 text-[4vh] lg:text-[2.6vh] icon transition-opacity duration-300"
            >
              <img
                src="/icon/youtube.svg"
                alt="YouTube"
                className="icon-image"
              />
            </a>
            <a
              href="https://www.facebook.com/YOUR_PAGE"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white items-center hover:opacity-50 text-[4vh] lg:text-[2.6vh] icon transition-opacity duration-300"
            >
              <img
                src="/icon/facebook.svg"
                alt="Facebook"
                className="icon-image"
              />
            </a>
            <a
              href="https://www.instagram.com/YOUR_PROFILE"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white items-center hover:opacity-50 text-[4vh] lg:text-[2.6vh] icon transition-opacity duration-300"
            >
              <img
                src="/icon/instagram.svg"
                alt="Instagram"
                className="icon-image"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/YOUR_PROFILE"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white items-center hover:opacity-50 text-[4vh] lg:text-[2.6vh] icon transition-opacity duration-300"
            >
              <img
                src="/icon/linkedin.svg"
                alt="LinkedIn"
                className="icon-image"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="lg:mx-[6.17vw] border-t opacity-[40%] border-[#ffff] mt-[4vh] lg:mt-16 md:w-[87.0vw]"></div>
      <div className="py-[5vh] lg:py-0 lg:px-[7.29vw] flex lg:flex-row flex-col lg:pt-[2.78vh] lg:pb-[9.81vh]">
        <p className="text-white font-montserrat text-[3.5vw] lg:text-[0.833vw] !opacity-100 ">
          Copyright 2024, RAT Films
        </p>
        <p className="hidden lg:block text-[0.833vw] opacity-[100%] px-2">|</p>
        <p className="text-white pt-[2vh] lg:pt-0 font-montserrat text-[3.5vw] lg:text-[0.833vw] ">
          Crafted by{" "}
          <a
            href="https://www.togglehead.in/"
            target="_blank"
            className="hover:opacity-50"
            rel="noreferrer"
          >
            <span> Togglehead</span>
          </a>
        </p>
      </div>
    </footer>
  );
}
