import React, { useEffect, useState, useCallback } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import img from "../../assets/SliderImg/13.png"; // Dummy placeholder
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Thumbnail = React.memo(({ item }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [hoverTimeoutId, setHoverTimeoutId] = useState(null);
  const navigate = useNavigate();

  function handleWatchButton(e, id) {
    e.stopPropagation();
    navigate(`/watch/${id}`);
  }

  function handleCardClick(id) {
    navigate(`/short-documentaries/details/${id}`);
  }

  const handleZoomLeave = useCallback(() => {
    if (hoverTimeoutId) {
      clearTimeout(hoverTimeoutId);
      setHoverTimeoutId(null);
    }
    setIsHovered(false);

    // Stop video immediately when hover is removed
    setShowVideo(false);

    const iframe = document.getElementById(`vimeo-video-${item.videoId}`);
    if (iframe) {
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
  }, [item.videoId]);

  const handleZoom = useCallback(() => {
    setIsHovered(true);

    // Start video after a small delay
    const timeoutId = setTimeout(() => {
      setShowVideo(true);

      const iframe = document.getElementById(`vimeo-video-${item.videoId}`);
      if (iframe) {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          "*"
        );
      }
    }, 3000); // Delayed video start for smoothness

    return () => clearTimeout(timeoutId); // Cleanup timeout if hover is removed before the delay
  }, [item.videoId]);

  return (
    <div
      onMouseEnter={handleZoom}
      onMouseLeave={handleZoomLeave}
      className={`thumbItem w-[16.72vw] h-[38.8vh] cursor-pointer bg-[#121614] relative transform transition-transform duration-500   ${
        isHovered ? "scale-105" : "scale-100"
      }`}
      onClick={() => handleCardClick(item.videoId)}
    >
      <div className="h-full w-full ">
        {!isHovered && (
          <img
            src={item.thumbnail || img}
            alt={item.title}
            className="!w-[16.72vw] !h-[38.8vh] !object-cover"
          />
        )}
       
      </div>

      {isHovered && (
        <div className="w-full h-full ">
          <LazyLoadImage
            alt={item.title}
            src={item.thumbnail}
            className="w-full h-full object-cover"
            effect="blur"
          /> 
      

          {!showVideo && (
            <div className="absolute z-[9999] top-0 left-0 w-full h-1/2 flex items-center justify-center">
              <button className="text-center flex items-center justify-center md:w-[4vw] relative w-10 h-10 p-4 z-10">
                <img
                  src="/icon/Play.svg"
                  alt="Play button"
                  className="w-full h-auto"
                />
              </button>
            </div>
          )}

          {showVideo && (
            <iframe
              id={`vimeo-video-${item.videoId}`}
              src={`https://player.vimeo.com/video/${item.videoId}?autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0`}
              title="Vimeo video"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "50%",
                pointerEvents: showVideo ? "auto" : "none",
              }}
            ></iframe>
          )}

          <div className="absolute bottom-0 left-0 w-full h-auto transition-all duration-500 ease-in-out flex flex-col justify-start items-start text-white">
            <div className="z-10 w-full flex flex-col items-start md:pb-[1.74vh] md:px-[0.83vw]">
              <div className="flex items-center justify-start text-white text-xs md:text-[0.60vw] font-semibold font-montserrat pb-[1vh] gap-x-[0.28vw]">
                <div>{item.year}</div>
                <div className="w-[0.21vw] h-[0.32vh] opacity-50 rounded-full bg-white"></div>
                <div>{item.season}</div>
                <div className="w-[0.21vw] h-[0.32vh] opacity-50 rounded-full bg-white"></div>
                <div>{item.language || "Hindi"}</div>
                <div className="w-[0.21vw] h-[0.32vh] opacity-50 rounded-full bg-white"></div>
                <div className="text-white bg-[#36c31f69] rounded-[0.21vw] md:p-[3px]">
                  <span className="text-white">{item.rating}</span>
                </div>
              </div>

              <p className="hidden md:block font-montserrat font-normal text-xs md:text-[0.60vw] pb-[1.57vh] leading-relaxed">
                {item.description}
              </p>

              <div className="w-full flex items-center justify-start pb-6">
                <div
                  className="border-[0.03rem] border-white py-[1vh] px-[0.56vw] md:mt-2 rounded-[3.2vw] relative overflow-hidden group cursor-pointer"
                  onClick={(e) => handleWatchButton(e, item.videoId)}
                >
                  <button className="flex items-center gap-2 text-white uppercase font-semibold relative z-10 font-montserrat text-xs md:text-[0.60vw]">
                    <span>Watch Trailer</span>
                    <div className="flex items-center justify-center bg-[#4AB66C] rounded-full transition-colors duration-300 ease-in-out group-hover:bg-white">
                      <BiRightArrowAlt className="text-white w-[1.3vw] h-auto group-hover:text-[#22AD86] -rotate-45 group-hover:rotate-0" />
                    </div>
                  </button>
                  <div className="absolute inset-0 bg-custom-vertical-gradient transform translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Thumbnail;
