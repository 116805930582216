/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import bg from "../../assets/bg_image/banner.png";
import CustomPlaybutton from "../../components/Buttons/CustomPlaybutton";
import Button from "../../components/Buttons/Button";
import Section from "../../components/Web series section/Section";
import Tvcs from "../../components/TVC_Section/Tvcs";
import Contact from "../../components/contact/Contact";
import ShortFilms from "../../components/shortfilms/ShortFilms";
import "./Home.scss";
import Partners from "../../components/ourpartners/Partners";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
// Thumbnail URLs
import thumb_1 from "../../assets/TVCS_Video/video_1_files/FENA.dcf1daa7.png";
import thumb_2 from "../../assets/TVCS_Video/video_1_files/Birthday-Party-thumbnail.2d17eb0e.png";
import thumb_3 from "../../assets/TVCS_Video/video_1_files/DIVIDE.07a3493d.png";
import thumb_4 from "../../assets/TVCS_Video/video_1_files/Innovative-Friend.372ed62b.png";
import thumb_5 from "../../assets/TVCS_Video/video_1_files/Airy-Updo.30a5b5ef.jpg";
import thumb_6 from "../../assets/TVCS_Video/video_1_files/Cafe.55a67dbd.png";
import thumb_7 from "../../assets/TVCS_Video/video_1_files/beti-film-70.550add3f.jpg";
import thumb_8 from "../../assets/TVCS_Video/video_1_files/Daddy-No1.b7308bf5.png";

const Home = () => {
  const [videoChunks, setVideoChunks] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [dotClicked, setDotClicked] = useState(false);
  const iframeRef = useRef(null);
  const playerRef = useRef(null);
  const textRef = useRef(null);
  const navigate = useNavigate();

  let videoId = 330036411;

  useEffect(() => {
    const handleScroll = () => {
      if (textRef.current) {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const docHeight = document.documentElement.scrollHeight - windowHeight;
        const scrollPercent = Math.min((scrollTop / docHeight) * 100, 100);

        const letters = textRef.current.querySelectorAll(".letter");
        const totalLetters = letters.length;

        // Ensure the fill percentage is calculated correctly
        const fillStart = 10; // Start filling at 10%
        const fillEnd = 16; // End filling at 30%
        const normalizedScrollPercent = Math.min(
          (Math.max(scrollPercent - fillStart, 0) / (fillEnd - fillStart)) *
            100,
          100
        );

        const fillPercentage = (normalizedScrollPercent / 100) * totalLetters;

        letters.forEach((letter, index) => {
          if (index < fillPercentage) {
            letter.classList.add("animate");
            letter.classList.remove("unfill");
          } else {
            letter.classList.add("unfill");
            letter.classList.remove("animate");
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    segmentDataIntoChunks(videoData);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const segmentDataIntoChunks = (data) => {
    const chunkSize = 8;
    const chunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    setVideoChunks(chunks);
  };

  const videoData = [
    { id: 1, videoId: 521257091, thumbnail: thumb_1 },
    { id: 2, videoId: 538104749, thumbnail: thumb_2 },
    { id: 3, videoId: 521252910, thumbnail: thumb_3 },
    { id: 4, videoId: 334856957, thumbnail: thumb_4 },
    { id: 5, videoId: 333499420, thumbnail: thumb_5 },
    { id: 6, videoId: 538105209, thumbnail: thumb_6 },
    { id: 7, videoId: 435127892, thumbnail: thumb_7 },
    { id: 8, videoId: 538102928, thumbnail: thumb_8 },
  ];

  const splitText = (text) => {
    return text.split(" ").map((word, wordIndex) => (
      <span key={wordIndex} className="word">
        {word.split("").map((char, charIndex) => (
          <span
            key={charIndex}
            className="letter"
            style={{ "--index": charIndex }}
          >
            {char}
          </span>
        ))}
        &nbsp;
      </span>
    ));
  };

  const handleScrollDown = (e) => {
    e.stopPropagation();
    setDotClicked(true);

    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });

    setTimeout(() => {
      setDotClicked(false);
    }, 1000);
  };

  // Initialize Vimeo player
  useEffect(() => {
    if (iframeRef.current) {
      playerRef.current = new Player(iframeRef.current);

      playerRef.current.on("loaded", () => {
        setIsVideoLoaded(true);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy(); // Clean up the player
      }
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
    playVideoFor20Seconds();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    stopVideo();
  };

  const playVideoFor20Seconds = () => {
    if (playerRef.current) {
      playerRef.current.setCurrentTime(0); // Start from the beginning
      playerRef.current.setLoop(true); // Enable looping
      playerRef.current.play(); // Play the video

      setTimeout(() => {
        if (playerRef.current) {
          playerRef.current.pause(); // Pause the video after 20 seconds
          playerRef.current.setLoop(false); // Stop looping after 20 seconds
        }
      }, 10000); // 20 seconds
    }
  };

  const stopVideo = () => {
    if (playerRef.current) {
      playerRef.current.pause(); // Pause the video
    }
  };

  const handleVideoAreaClick = (e) => {
    navigate(`/watch/${videoId}`);
  };

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className="w-full h-full grid grid-cols-1 m-0 p-0 overflow-hidden relative">
      <div
        className="relative w-full flex items-center !overflow-hidden justify-center min-h-screen  "
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleVideoAreaClick}
      >
        {isHovered && (
          <div className="absolute inset-0 z-10 ">
            <div className="relative pb-[56.25%] md:scale-100 h-screen !cursor-pointer  ">
              {isHovered && (
                <div className="vimeo-full-width">
                  {/* Local video for mobile devices */}
                  <video
                    src="/video/videoplayback.mp4" // Local video from public folder
                    className={`mobile-video w-full h-full absolute top-0 left-0  transition-opacity duration-500 ${
                      isHovered || isVideoLoaded ? "opacity-100" : "opacity-0"
                    }`}
                    autoPlay={isHovered}
                    loop
                    muted
                    controls={false}
                    // onCanPlay={handleVideoLoad}
                    onError={() =>
                      console.error("Failed to load the local video.")
                    }
                    style={{ pointerEvents: "none" }}
                  />

                  {/* Vimeo iframe for larger screens */}
                  <iframe
                    src={`https://player.vimeo.com/video/${videoId}?playsinline=0&autoplay=${
                      isHovered ? 1 : 0
                    }&loop=1&muted=1&controls=0`}
                    className={`desktop-video w-full h-full absolute top-0 left-0  transition-opacity duration-500 ${
                      isHovered || isVideoLoaded ? "opacity-100" : "opacity-0"
                    }`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    // onLoad={handleVideoLoad}
                    onError={() =>
                      console.error("Failed to load the Vimeo video.")
                    }
                    style={{ pointerEvents: "none" }}
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        )}

        {!isHovered && (
          <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center">
            <CustomPlaybutton onClick={() => navigate(`/watch/${videoId}`)} />
          </div>
        )}

        <div className="absolute left-1/2 transform -translate-x-1/2 md:bottom-2 bottom-6 text-white flex items-center flex-col z-20 cursor-pointer">
          <div
            className="rounded-full border-white border-2 md:w-[2.14vw] md:h-[5.74vh] w-[28px] h-[42px] p-2 md:pt-[1.20370vh] pt-2 flex items-start justify-center "
            onClick={handleScrollDown}
          >
            <div
              className={` w-2 h-2 bg-white rounded-full transition-transform duration-500 ease-in-out animate-bounce ${
                dotClicked ? "translate-y-[22px] scale-125" : ""
              }`}
            ></div>
          </div>
          <p className="md:text-[1.15vw]  text-[4.8vw]  font-light font-montserrat md:pb-[1.85vh]  text-white">
            Scroll to explore
          </p>
        </div>
      </div>

      <div className=" w-full h-full  font-bebas_neue flex items-start flex-col justify-between     md:pl-[7.29vw] px-4  ">
        <div
          className="py-[5.56vh] md:py-0 md:pt-[10vh] md:pb-[9.43vh]"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <p
            className="md:max-w-[74.12vw] md:max-h-[32.78vh]   text-[#484848] md:text-[5.73vw] text-[11.2vw]  tracking-normal opacity-100 text-left   break-keep whitespace-pre-line leading-[1.2]  text-wrap"
            ref={textRef}
          >
            {splitText(
              "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPESETTING INDUSTRY LOREM IPSUM HAS BEEN THE"
            )}
          </p>
        </div>

        <div
          className="flex items-start md:pl-[0.3vw] pl-0 md:pt-[5.5vh] "
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <Button
            buttonText={"KNOW MORE"}
            onClick={() => navigate("/about-us")}
          />
        </div>
      </div>

      <Section   />

      <Tvcs videos={videoChunks} />

      <ShortFilms />

      <Partners />

      <Contact />
    </div>
  );
};

export default Home;
